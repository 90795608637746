import axios from "axios";
import { sentryLog } from "../helpers/sentryLog";

const HOST = "https://content.the.coach";

const configureApi = (baseURL?: string) => axios.create({ baseURL });

export enum ApiPaths {
  "BRAINTREE_PLANS" = "braintree_plans/",
  "BRAINTREE_TOKEN" = "braintree_token/",
  "BRAINTREE_SUBSCRIPTION_NOAUTH" = "braintree_subscription_noauth/",
  "SEND_EMAIL" = "deeplink/save_email/",
  "PRE_SALE" = "braintree_subscription_upsell/",
  "CHAT_BOT" = "chatwithcoach/",
  "CHAT_BOT_START" = "/api/v1/aicoach/start",
  "QUESTIONNAIRE_SUBMIT" = "/api/v1/onboarding_questions/",
}

const api = configureApi(HOST);

// Add a request interceptor
api.interceptors.request.use(
  (config: any) => {
    // Attach the start time to the config metadata
    (config as any).metadata = { startTime: new Date().getTime() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use((response: any) => {
  const endTime = new Date().getTime();
  const startTime = (response.config as any).metadata.startTime;
  const duration = endTime - startTime;

  sentryLog("Request LOG", {
    url: response.config.url,
    method: response.config.method,
    status: response.status,
    duration: `${duration}ms`,
  });

  return response;
});

export { api };
